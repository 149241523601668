import { PAGINATE_LIMIT } from "../../components/common/Constants";
import { api } from "../../config/api";
import {
  GET_SIEM_SERIALS,
  UPDATE_SIEM_SERIAL,
  DELETE_SIEM_SERIAL,
  ADD_SIEM_SERIAL,
  ADD_SIEM_SERIAL_ERR,
  GET_SIEM_SERIALS_ERR,
  UPDATE_SIEM_SERIAL_ERR,
  DELETE_SIEM_SERIAL_ERR,
} from "./types";
import { toast } from "react-toastify";
import axios from "axios";


const toArrayBuf = (s) => {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

export const exportSiemSerials =
  (accessToken, filterStr, export_type, setExportLoading) =>
    (dispatch) => {
      let url = filterStr ? `/export_siem_serials?export_type=${export_type}${filterStr}` :
        `/export_siem_serials?export_type=${export_type}`
      axios.create({
        baseURL: window.location.origin + process.env.REACT_APP_API_URL,
        timeout: 60 * 10 * 1000,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).get(url)
        .then((response) => {
          let filename = "export.csv"
          let blob = new Blob([response.data], {});
          if (export_type === "excel") {
            filename = "export.xlsx"
            blob = new Blob([toArrayBuf(atob(response.data))], {});
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).then(() => {
          toast.success("Exported successfully.");
        }).catch((error) => {
          if (error.response !== undefined) {
            toast.error(error.response.data.detail);
          } else if (typeof error === "object" && "message" in error && error.message.includes("timeout")) {
            toast.error("Requested data is too large, please use filters and try again.", { pauseOnHover: true, autoClose: 1000 });
          }
        }).finally(() => {
          setExportLoading(false)
        });
    };

export const getSiemSerials =
  (accessToken, limit = PAGINATE_LIMIT, offset = 0, filterStr) =>
    (dispatch) => {
      let url = filterStr ? `/siem_serials?limit=${limit}&offset=${offset}${filterStr}` :
        `/siem_serials?limit=${limit}&offset=${offset}`
      api(accessToken)
        .get(url)
        .then((response) => {
          dispatch({ type: GET_SIEM_SERIALS, payload: response.data });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch({ type: "LOGIN_AGAIN" });
          }
          dispatch({ type: GET_SIEM_SERIALS_ERR, payload: error.response.data });
        });
    };

export const addSiemSerial = (accessToken, data) => (dispatch) => {
  api(accessToken)
    .post("/siem_serial", data)
    .then((response) => {
      dispatch({ type: ADD_SIEM_SERIAL, payload: response.data });
      toast.success("Serial successfully created.");
    })
    .catch((error) => {
      dispatch({ type: ADD_SIEM_SERIAL_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};

export const updateSiemSerial = (accessToken, data) => (dispatch) => {
  api(accessToken)
    .put(`/siem_serial/${data.id}`, data)
    .then((response) => {
      dispatch({ type: UPDATE_SIEM_SERIAL, payload: response.data });
      toast.success("Serial successfully updated.");
    })
    .catch((error) => {
      dispatch({ type: UPDATE_SIEM_SERIAL_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};

export const deleteSiemSerial = (accessToken, id) => (dispatch) => {
  api(accessToken)
    .delete(`/siem_serial/${id}`)
    .then(() => {
      dispatch({ type: DELETE_SIEM_SERIAL, payload: id });
      toast.success("Serial successfully deleted.");
    })
    .catch((error) => {
      dispatch({ type: DELETE_SIEM_SERIAL_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};


export const downloadSiemSerialCertificate = (accessToken, serial_key) => (dispatch) => {
  api(accessToken)
    .get(`/siem_serial/download_cert/${serial_key}`)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "certificate.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).then(() => {
      toast.success("Certificate downloaded successfully.");
    }).catch((error) => {
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    })
};

