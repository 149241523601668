import axios from 'axios';

export function api(accessToken) {
  return axios.create({
    baseURL: window.location.origin + process.env.REACT_APP_API_URL,
    timeout: 60 * 5 * 10000,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
