import React from "react";

import { selectSignedIn } from "./redux/reducers/auth";
import { useSelector } from "react-redux";
import Login from "./components/Login/Login";
import HomePage from "./components/HomePage/HomePage";
import LicenseList from "./components/License/LicenseList";

import { Route, Routes } from "react-router-dom";
import UserList from "./components/User/UserList";
import SerialList from "./components/Serial/SerialList";
import PartnerList from "./components/Partner/PartnerList";

import { ToastContainer } from "react-toastify";

function App() {
  const isSignedIn = useSelector(selectSignedIn);

  return (
    <div className="App">
      {isSignedIn ? (
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/partners" element={<PartnerList />} />
          <Route path="/siem_licenses" element={<LicenseList />} />
          <Route path="/siem_serials" element={<SerialList />} />
        </Routes>
      ) : (
        <Login />
      )}
      <ToastContainer
        position="top-right"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false} />
    </div>
  );
}

export default App;
