import React, { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Divider, Button } from "antd";
import { toast } from "react-toastify";

import "./login.css";
import { selectSignedIn } from "../../redux/reducers/auth";
import { getAccessToken } from "../../redux/actions/accessToken";


const Login = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(selectSignedIn);

  useEffect(() => {
    let gAccessToken = getFromLocalStore();
    if (gAccessToken) {
      dispatch(getAccessToken(gAccessToken));
    }
  }, []);


  const failure = () => {
    return toast.error("Google Login failed.");
  };

  const saveToLocalStore = (v) => {
    localStorage.setItem("access_token", v);
  };

  const getFromLocalStore = () => {
    return localStorage.getItem("access_token");
  };

  const handleGoogleLogin = (response) => {
    if (response.error) {
      return failure();
    }
    saveToLocalStore(response.access_token);
    dispatch(getAccessToken(response.access_token));
    return response
  }

  const gOauth = useGoogleLogin({
    onSuccess: response => handleGoogleLogin(response),
    flow: 'implicit',
  });


  return (
    <div className="login">
      <Row align="middle" justify="center">
        <Col span={24} align="center">
          <Divider>
            <h2>Logsign License Server</h2>
          </Divider>
          {!isSignedIn && (
            <Button onClick={() => gOauth()}>
              Sign in with Google 🚀
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Login;
