import { PAGINATE_LIMIT } from "../../components/common/Constants";
import { api } from "../../config/api";
import {
    GET_SALES_OWNERS,
    DELETE_SALES_OWNER,
    UPDATE_SALES_OWNER,
    ADD_SALES_OWNER,
    ADD_SALES_OWNER_ERR,
    UPDATE_SALES_OWNER_ERR,
    DELETE_SALES_OWNER_ERR,
    GET_SALES_OWNER_ERR,
} from "./types";
import { toast } from "react-toastify";

export const getSalesOwners =
    (accessToken, limit = PAGINATE_LIMIT, offset = 0, email) =>
        (dispatch) => {
            let url = email  ? `/sales_owners?limit=${limit}&offset=${offset}&email=${email}` :
              `/sales_owners?limit=${limit}&offset=${offset}`
            api(accessToken)
                .get(url)
                .then((response) => {
                    dispatch({ type: GET_SALES_OWNERS, payload: response.data });
                })
                .catch((error) => {
                    dispatch({ type: GET_SALES_OWNER_ERR, payload: error.message });
                    if (error.response !== undefined) {
                        toast.error(error.response.data.detail);
                    }
                });
        };

export const addSalesOwner = (accessToken, data) => (dispatch) => {
    api(accessToken)
        .post("/sales_owner", data)
        .then((response) => {
            dispatch({ type: ADD_SALES_OWNER, payload: response.data });
            toast.success("Sales owner successfully added.");
        })
        .catch((error) => {
            dispatch({ type: ADD_SALES_OWNER_ERR, payload: error.message });
            if (error.response !== undefined) {
                toast.error(error.response.data.detail);
            }
        });
};

export const updateSalesOwner = (accessToken, newData) => (dispatch) => {
    api(accessToken)
        .put(`/sales_owner/${newData.id}`, newData)
        .then((response) => {
            dispatch({ type: UPDATE_SALES_OWNER, payload: response.data });
            toast.success("Sales owner successfully updated.");
        })
        .catch((error) => {
            dispatch({ type: UPDATE_SALES_OWNER_ERR, payload: error.message });
            if (error.response !== undefined) {
                toast.error(error.response.data.detail);
            }
        });
};

export const deleteSalesOwner = (accessToken, id) => (dispatch) => {
    api(accessToken)
        .delete(`/sales_owner/${id}`)
        .then(() => {
            dispatch({ type: DELETE_SALES_OWNER, payload: id });
            toast.success("Sales owner successfully deleted.");
        })
        .catch((error) => {
            dispatch({ type: DELETE_SALES_OWNER_ERR, payload: error.message });
            if (error.response !== undefined) {
                toast.error(error.response.data.detail);
            }
        });
};
