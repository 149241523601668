import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSiemSerials,
  addSiemSerial,
  deleteSiemSerial,
  updateSiemSerial,
  downloadSiemSerialCertificate,
  exportSiemSerials
} from "../../redux/actions/serial";

import {
  Table,
  Input,
  Button,
  Modal,
  Col,
  Row,
  Select,
  PageHeader,
  InputNumber,
  DatePicker,
  Switch,
  Tooltip,
  Popover,
  Tag
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
  CloseOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import {
  isAdmin,
  PAGINATE_LIMIT,
} from "../common/Constants";
import { api } from "../../config/api";
import Filter from "../Filter";
import Exporter from "../Exporter";

const { Option } = Select;

const SerialList = (props) => {
  const dispatch = useDispatch();
  const serialList = useSelector((state) => state.serial.serialList);
  const totalSiemSerialCount = useSelector((state) => state.serial.totalSiemSerialCount);

  const accessToken = useSelector((state) => state.access_token.accessToken);
  const userRole = useSelector((state) => state.access_token.userRole);
  const [currentPage, setCurrentPage] = useState({
    offset: 0,
    limit: PAGINATE_LIMIT,
  });
  const [salesOwners, setSalesOwners] = useState([]);
  const [partners, setPartners] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [visible, setVisible] = useState(false);
  const [editSerial, setEditSerial] = useState(null);
  const [infoSerial, setInfoSerial] = useState(null);
  const [addSerialData, setAddSerialData] = useState(null);
  const [isAddSerial, setIsAddSerial] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [ueba_module_enabled, setUebaModuleEnabled] = useState(false);

  const title = "Serials";
  let filterStr = ``;
  const validInputRegex = /^[A-Za-z0-9-_\s]*$/;

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const hide = () => {
    setVisible(false);
  };

  const getSalesOwners = () => {
    api(accessToken)
      .get("/sales_owners")
      .then((response) => {
        setSalesOwners(response.data.sales_owners);
      });
  };

  useEffect(() => {
    getSalesOwners();
  }, []);

  const getAllPartners = () => {
    api(accessToken)
      .get("/all_partners")
      .then((response) => {
        setPartners(response.data.partners);
      });
  };

  useEffect(() => {
    getAllPartners();
  }, []);


  useEffect(() => {
    setTimeout(() => {
      dispatch(getSiemSerials(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
    }, 100);
  }, [currentPage, accessToken, dispatch, isEdit, isAddSerial]);

  const onDeleteSerial = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete serial record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(deleteSiemSerial(accessToken, record.id));
        setTimeout(() => {
          dispatch(getSiemSerials(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
        }, 100);
      },
    });
  };

  const onInfoSerial = (record) => {
    setIsInfo(true);
    setInfoSerial({ ...record });
  };

  const onEditSerial = (record) => {
    setIsEdit(true);
    setEditSerial({ ...record });
  };

  const onDownloadCert = (serial_key) => {
    dispatch(downloadSiemSerialCertificate(accessToken, serial_key));
  };

  const resetEditing = () => {
    setIsEdit(false);
    setEditSerial(null);
  };

  const resetAddSerial = () => {
    setIsAddSerial(false);
    setAddSerialData(null);
  };

  const handleFilter = useCallback(filterData => {
    filterStr = ``;
    Object.keys(filterData).map((key) => {
      filterData[key] && (filterStr = filterStr + `&${key}=${filterData[key]}`)
    })
    currentPage.filterStr = filterStr
    setCurrentPage(currentPage)
    setFilter(filterStr);
    dispatch(getSiemSerials(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
  }, [])

  const onExport = useCallback(exportType => {
    setExportLoading(true)
    dispatch(exportSiemSerials(accessToken, filter, exportType, setExportLoading));
  }, [filter]);

  const onPaginateChange = (page, _) => {
    let newOffset = page === 1 ? 0 : (page - 1) * PAGINATE_LIMIT
    setCurrentPage({ offset: newOffset, limit: PAGINATE_LIMIT, filterStr: currentPage?.filterStr })
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "company_name",
      key: "company_name",
      align: "center",
    },
    {
      title: "Created Time",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (created_at) => created_at.replace("T", " ")
    },
    {
      title: "Serial Key",
      dataIndex: "serial_key",
      key: "serial_key",
      align: "center",
      render: (serial_key) => (
        <Button
          type="dashed"
          onClick={() => {
            navigator.clipboard.writeText(serial_key);
          }}
        >
          <Tooltip title="Click & Copy Me">
            {serial_key}
          </Tooltip>
        </Button>
      ),
    },
    {
      title: "Trial",
      dataIndex: "trial",
      key: "trial",
      align: "center",
      render: (trial) => (trial ? <Tag color="green"> YES </Tag > : <Tag color="red"> NO </Tag >),
    },
    {
      title: "Source",
      dataIndex: "source_limit",
      key: "source_limit",
      align: "center",
    },
    {
      title: "EPS",
      dataIndex: "eps",
      key: "eps",
      align: "center",
    },
    {
      title: "License Status",
      dataIndex: "license_status",
      key: "license_status",
      align: "center",
      render: (license_status, serialList) => {
        if (license_status === "Active") {
          return (
            <>
              <Tag color="green"> {license_status} </Tag><br />
              <Tag color="green">Expire Time: {serialList?.license_expire_time} </Tag>
            </>);
        } else if (license_status === "Expired") {
          return (
            <> <Tag color="red"> {license_status} </Tag><br />
              <Tag color="red">Expire Time: {serialList?.license_expire_time} </Tag>
            </>);
        } else {
          return (
            <> <Tag color="#808080"> {license_status} </Tag><br />
              <Tag color="#808080">License Time: {serialList?.license_time} </Tag>
            </>);
        }
      }
    },
    {
      title: "Support Status",
      dataIndex: "support_status",
      key: "support_status",
      align: "center",
      render: (support_status, serialList) => {
        if (support_status === "Active") {
          return (
            <>
              <Tag color="green"> {support_status} </Tag><br />
              <Tag color="green">Expire Time: {serialList?.support_expire_time} </Tag>
            </>);
        } else if (support_status === "Expired") {
          return (
            <>
              <Tag color="red"> {support_status} </Tag><br />
              <Tag color="red">Expire Time: {serialList?.support_expire_time} </Tag>
            </>);
        } else {
          return (
            <>
              <Tag color="#808080"> {support_status} </Tag><br />
              <Tag color="#808080">Support Time: {serialList?.support_time} </Tag>
            </>);
        }
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => {
        if (isAdmin(userRole)) {
          return (
            <>
              <InfoCircleOutlined
                onClick={() => {
                  onInfoSerial(record);
                }}
                style={{ color: "black", marginLeft: 12 }} />
              <EditOutlined
                onClick={() => {
                  onEditSerial(record);
                }}
                style={{ color: "green", marginLeft: 12 }} />
              <DeleteOutlined
                onClick={() => {
                  onDeleteSerial(record);
                }}
                style={{ color: "red", marginLeft: 12 }} />
              <DownloadOutlined
                onClick={() => {
                  onDownloadCert(record.serial_key);
                }}
                style={{ marginLeft: 12 }} />
            </>
          )
        } else {
          return (
            <>
              <InfoCircleOutlined
                onClick={() => {
                  onInfoSerial(record);
                }}
                style={{ color: "black", marginLeft: 12 }} />
              <DownloadOutlined
                onClick={() => {
                  onDownloadCert(record.serial_key);
                }}
                style={{ marginLeft: 12 }} />
            </>
          )
        }
      },
    },
  ];

  return (
    <>
      <Filter name={"SerialList"} onFilter={handleFilter} />
      <PageHeader
        style={{ padding: "8px 0px" }}
        className="site-page-header"
        title={title}
        extra={[
          <Button
            key={"AddSerial"}
            type="primary"
            icon={<LockOutlined />}
            onClick={() => {
              setIsAddSerial(true);
            }}
          >
            Add Serial
          </Button>,
          <Exporter key={"exporter"} onExport={onExport} loading={exportLoading} />,
        ]}
      />

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={serialList}
        bordered={true}
        tableLayout={"auto"}
        pagination={{
          defaultPageSize: PAGINATE_LIMIT,
          total: totalSiemSerialCount,
          onChange: onPaginateChange
        }}
      />
      <Modal
        title="Info Serial"
        visible={isInfo}
        onCancel={() => {
          setIsInfo(false);
        }}
        okText="OK"
        onOk={() => {
          setIsInfo(false);
        }}
      >
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Serial Key:{" "}
          </Col>
          <Col span={12}>
            <Popover
              content={<a onClick={hide}>Copied</a>}
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <Button
                type="dashed"
                onClick={() => {
                  navigator.clipboard.writeText(infoSerial?.serial_key);
                }}
              >
                {infoSerial?.serial_key}
              </Button></Popover>
          </Col>
        </Row>
        {infoSerial?.license_expire_time && infoSerial?.license_expire_time !== 'Unlimited' ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              License Expire Time:{" "}
            </Col>
            <Col>
              <Input.Group compact>
                <DatePicker
                  disabled
                  value={moment(infoSerial?.license_expire_time)}
                />
              </Input.Group>
            </Col>
          </Row>
        ) : null}
        {infoSerial?.license_status === 'Inactive' || infoSerial?.license_status === 'Expired' ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              License Time:
            </Col>
            <Col>
              <Tooltip placement="right" title="Please enter 0 for the unlimited LICENSE.">
                <Input.Group compact>
                  <InputNumber disabled
                    style={{ width: '100px' }}
                    value={infoSerial?.license_time}
                  />
                </Input.Group>
              </Tooltip>
            </Col>
          </Row>
        ) : null}
        {infoSerial?.support_expire_time ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              Support Expire Time:{" "}
            </Col>
            <Col span={12}>
              <Input.Group compact>
                <DatePicker
                  disabled
                  value={moment(infoSerial?.support_expire_time)} />
              </Input.Group>
            </Col>
          </Row>
        ) : null}
        {infoSerial?.license_status === 'Inactive' || infoSerial?.license_status === 'Expired' ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              Support Time:{" "}
            </Col>
            <Col>
              <Input.Group compact>
                <InputNumber disabled
                  style={{ width: '100px' }}
                  value={infoSerial?.support_time}
                />
              </Input.Group>
            </Col>
          </Row>
        ) : null}
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Product Code:{" "}
          </Col>
          <Col span={12}>
            <Input disabled value={infoSerial?.product_code} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Company Name:{" "}
          </Col>
          <Col span={12}>
            <Input disabled value={infoSerial?.company_name} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Company Phone:{" "}
          </Col>
          <Col span={12}>
            <Input disabled value={infoSerial?.company_phone} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Company Email:{" "}
          </Col>
          <Col span={12}>
            <Input disabled value={infoSerial?.company_email} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            EPS:{" "}
          </Col>
          <Col>
            <InputNumber disabled value={infoSerial?.eps} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Source Limit:{" "}
          </Col>
          <Col>
            <InputNumber disabled value={infoSerial?.source_limit} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Alert Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.alert_module}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Response Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.response_module_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Vulnerability Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.vulnerability_module_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Leaf Mode:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.leaf_mode_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Incident Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.incident_module}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            MSS:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                  checked={infoSerial?.mss_enabled}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Incident Report Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                  checked={infoSerial?.incident_report_module}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Reputation Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.ti_module}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Secops:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.secops}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Logops:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.logops}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            UEBA Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={infoSerial?.ueba_module_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            UEBA User Limit:{" "}
          </Col>
          <Col>
            <InputNumber disabled value={infoSerial?.ueba_user_limit} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Node:{" "}
          </Col>
          <Col>
            <InputNumber disabled value={infoSerial?.node} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Node List:{" "}
          </Col>
          <Col>
            {infoSerial?.nodes && infoSerial?.nodes.map((node, _) => {
              return (
                <Tag disabled>{node}</Tag>
              );
            })}
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Sales Owner:{" "}
          </Col>
          <Col span={12}>
            <Input.Group compact>
              <Select disabled value={infoSerial?.sales_owner_id} >
                {salesOwners.map((sales_owner) => {
                  return (
                    <Option key={sales_owner.id} value={sales_owner.id}>
                      {sales_owner.name + " " + sales_owner.surname}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Partner:{" "}
          </Col>
          <Col span={12} style={{ marginBottom: "5px" }}>
            <Input.Group compact>
              <Select disabled value={infoSerial?.partner_id} >
                {partners.map((partner) => {
                  return (
                    <Option key={partner.id} value={partner.id}>
                      {partner.name}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Note:{" "}
          </Col>
          <Col span={12}>
            <Input.TextArea
              disabled
              value={infoSerial?.note}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Edit Serial"
        visible={isEdit}
        onCancel={() => {
          resetEditing();
        }}
        okText="Save"
        onOk={() => {
          dispatch(updateSiemSerial(accessToken, editSerial));
          resetEditing();
        }}
      >
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Serial Key:{" "}
          </Col>
          <Col span={12}>
            <Popover
              content={<a onClick={hide}>Copied</a>}
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <Button
                type="dashed"
                onClick={() => {
                  navigator.clipboard.writeText(editSerial?.serial_key);
                }}
              >
                {editSerial?.serial_key}
              </Button></Popover>
          </Col>
        </Row>
        {editSerial?.license_expire_time && editSerial?.license_expire_time !== 'Unlimited' ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              License Expire Time:{" "}
            </Col>
            <Col>
              <Input.Group compact>
                <DatePicker
                  placeholder="Select a license expire time"
                  value={moment(editSerial?.license_expire_time)}
                  onChange={(date, _) => {
                    if (date != null) {
                      setEditSerial((serial) => {
                        return {
                          ...serial,
                          license_expire_time: date.format(process.env.REACT_APP_DATE_FORMAT),
                        };
                      });
                    }
                  }}
                />
              </Input.Group>
            </Col>
          </Row>
        ) : null}
        {editSerial?.license_status === 'Inactive' ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              License Time:
            </Col>
            <Col>
              <Tooltip placement="right" title="Please enter 0 for the unlimited LICENSE.">
                <Input.Group compact>
                  <InputNumber
                    style={{ width: '100px' }}
                    min={0}
                    max={72}
                    placeholder="Month(s)"
                    value={editSerial?.license_time}
                    onChange={(value) => {
                      setEditSerial((serial) => {
                        return { ...serial, license_time: value };
                      });
                    }}
                  />
                </Input.Group>
              </Tooltip>
            </Col>
          </Row>
        ) : null}
        {editSerial?.support_expire_time ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              Support Expire Time:{" "}
            </Col>
            <Col span={12}>
              <Input.Group compact>
                <DatePicker
                  placeholder="Select a support expire time"
                  value={moment(editSerial?.support_expire_time)}
                  onChange={(date, _) => {
                    if (date != null) {
                      setEditSerial((serial) => {
                        return {
                          ...serial,
                          support_expire_time: date.format(process.env.REACT_APP_DATE_FORMAT),
                        };
                      });
                    }
                  }}
                />
              </Input.Group>
            </Col>
          </Row>
        ) : null}
        {editSerial?.license_status === 'Inactive' ? (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              Support Time:{" "}
            </Col>
            <Col>
              <Input.Group compact>
                <InputNumber
                  style={{ width: '100px' }}
                  min={1}
                  max={72}
                  value={editSerial?.support_time}
                  placeholder="Month(s)"
                  onChange={(value) => {
                    setEditSerial((serial) => {
                      return { ...serial, support_time: value };
                    });
                  }}
                />
              </Input.Group>
            </Col>
          </Row>
        ) : null}
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Product Code:{" "}
          </Col>
          <Col span={12}>
            <Input
              value={editSerial?.product_code}
              status={validInputRegex.test(editSerial?.product_code) ? "" : "error"}
              onChange={(e) => {
                setEditSerial((serial) => {
                  return { ...serial, product_code: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Company Name:{" "}
          </Col>
          <Col span={12}>
            <Input
              value={editSerial?.company_name}
              status={validInputRegex.test(editSerial?.company_name) ? "" : "error"}
              onChange={(e) => {
                setEditSerial((serial) => {
                  return { ...serial, company_name: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Company Phone:{" "}
          </Col>
          <Col span={12}>
            <Input
              value={editSerial?.company_phone}
              onChange={(e) => {
                setEditSerial((serial) => {
                  return { ...serial, company_phone: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Company Email:{" "}
          </Col>
          <Col span={12}>
            <Input
              value={editSerial?.company_email}
              onChange={(e) => {
                setEditSerial((serial) => {
                  return { ...serial, company_email: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            EPS:{" "}
          </Col>
          <Col>
            <InputNumber
              min={0}
              value={editSerial?.eps}
              onChange={(value) => {
                setEditSerial((serial) => {
                  return { ...serial, eps: value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Source Limit:{" "}
          </Col>
          <Col>
            <InputNumber
              min={0}
              value={editSerial?.source_limit}
              onChange={(value) => {
                setEditSerial((serial) => {
                  return { ...serial, source_limit: value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Alert Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.alert_module}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, alert_module: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Response Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.response_module_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, response_module_enabled: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Vulnerability Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.vulnerability_module_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, vulnerability_module_enabled: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Leaf Mode:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.leaf_mode_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, leaf_mode_enabled: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Incident Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.incident_module}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, incident_module: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            MSS:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                  checked={editSerial?.mss_enabled}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => {
                    setEditSerial((serial) => {
                      return { ...serial, mss_enabled: checked };
                    });
                  }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Incident Report Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                  checked={editSerial?.incident_report_module}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => {
                    setEditSerial((serial) => {
                      return { ...serial, incident_report_module: checked };
                    });
                  }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Reputation Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.ti_module}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, ti_module: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Secops:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.secops}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, secops: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Logops:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.logops}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, logops: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            UEBA Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editSerial?.ueba_module_enabled}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditSerial((serial) => {
                    return { ...serial, ueba_module_enabled: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        {editSerial?.ueba_module_enabled && (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              UEBA User Limit:{" "}
            </Col>
            <Col>
              <InputNumber
                min={0}
                value={editSerial?.ueba_user_limit}
                onChange={(value) => {
                  if (value === null) {
                    return;
                  }
                  setEditSerial((serial) => {
                    return { ...serial, ueba_user_limit: value };
                  });
                }}
              />
            </Col>
          </Row>)}
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Node:{" "}
          </Col>
          <Col>
            <InputNumber
              min={0}
              value={editSerial?.node}
              onChange={(value) => {
                setEditSerial((serial) => {
                  return { ...serial, node: value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Node List:{" "}
          </Col>
          <Col>
            {editSerial?.nodes && editSerial?.nodes.map((node, _) => {
              return (
                <Tag closable onClose={(e) => {
                  e.preventDefault();
                  setEditSerial((serial) => {
                    return { ...serial, nodes: serial.nodes.filter((n, i) => i !== _) };
                  });
                }}>{node}</Tag>
              );
            })}
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Sales Owner:{" "}
          </Col>
          <Col span={12}>
            <Input.Group compact>
              <Select
                placeholder="Select a owner"
                value={editSerial?.sales_owner_id}
                onChange={(sales_owner_id) => {
                  setEditSerial((serial) => {
                    return { ...serial, sales_owner_id: sales_owner_id };
                  });
                }}
              >
                {salesOwners.map((sales_owner) => {
                  return (
                    <Option key={sales_owner.id} value={sales_owner.id}>
                      {sales_owner.name + " " + sales_owner.surname}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Partners{" "}
          </Col>
          <Col span={12} style={{ marginBottom: "5px" }}>
            <Input.Group compact>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select a partner"
                value={editSerial?.partner_id}
                onChange={(partner_id) => {
                  setEditSerial((serial) => {
                    return { ...serial, partner_id: partner_id };
                  });
                }}
              >
                {partners.map((partner) => {
                  return (
                    <Option key={partner.id} value={partner.id}>
                      {partner.name}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Note:{" "}
          </Col>
          <Col span={12}>
            <Input.TextArea
              value={editSerial?.note}
              status={validInputRegex.test(editSerial?.note) ? "" : "error"}
              onChange={(e) => {
                setEditSerial((serial) => {
                  return { ...serial, note: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Add New Serial"
        visible={isAddSerial}
        onCancel={() => {
          resetAddSerial();
        }}
        okText="Save"
        onOk={() => {
          dispatch(addSiemSerial(accessToken, addSerialData));
          resetAddSerial();
        }}
        destroyOnClose={true}
      >
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            License Time:
          </Col>
          <Col>
            <Tooltip placement="right" title="Please enter 0 for the unlimited LICENSE.">
              <Input.Group compact>
                <InputNumber
                  style={{ width: '100px' }}
                  min={0}
                  max={72}
                  placeholder="Month(s)"
                  onChange={(value) => {
                    setAddSerialData((serial) => {
                      return { ...serial, license_time: value };
                    });
                  }}
                />
              </Input.Group>
            </Tooltip>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Support Time:{" "}
          </Col>
          <Col>
            <Input.Group compact>
              <InputNumber
                style={{ width: '100px' }}
                min={1}
                max={72}
                placeholder="Month(s)"
                onChange={(value) => {
                  setAddSerialData((serial) => {
                    return { ...serial, support_time: value };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            EPS:{" "}
          </Col>
          <Col>
            <Tooltip placement="right" title="Please enter 0 for the unlimited EPS.">
              <Input.Group compact>
                <InputNumber
                  style={{ width: '100px' }}
                  min={0}
                  placeholder="EPS"
                  onChange={(value) => {
                    setAddSerialData((serial) => {
                      return { ...serial, eps: value };
                    });
                  }}
                />
              </Input.Group>
            </Tooltip>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Source Limit:{" "}
          </Col>
          <Col>
            <Tooltip placement="right" title="Please enter 0 for the unlimited SOURCE.">
              <Input.Group compact>
                <InputNumber
                  style={{ width: '100px' }}
                  min={0}
                  placeholder="Source"
                  onChange={(value) => {
                    setAddSerialData((serial) => {
                      return { ...serial, source_limit: value };
                    });
                  }}
                />
              </Input.Group>
            </Tooltip>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Node:{" "}
          </Col>
          <Col>
            <Input.Group compact>
              <InputNumber
                style={{ width: '100px' }}
                min={0}
                placeholder="Node"
                onChange={(value) => {
                  setAddSerialData((serial) => {
                    return { ...serial, node: value };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Alert Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, alert_module: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Response Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, response_module_enabled: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Vulnerability Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, vulnerability_module_enabled: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Leaf Mode:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, leaf_mode_enabled: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Incident Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, incident_module: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            MSS:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => {
                    setAddSerialData((serial) => {
                      return { ...serial, mss_enabled: checked };
                    });
                  }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Incident Report Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => {
                    setAddSerialData((serial) => {
                      return { ...serial, incident_report_module: checked };
                    });
                  }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Reputation Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, ti_module: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Secops:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, secops: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Logops:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    return { ...serial, logops: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            UEBA Module:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddSerialData((serial) => {
                    setUebaModuleEnabled(checked);
                    return { ...serial, ueba_module_enabled: checked, ueba_user_limit: 0 };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        {ueba_module_enabled && (
          <Row align="middle">
            <Col span={6} style={{ margin: "10px" }}>
              UEBA User Limit:{" "}
            </Col>
            <Col>
              <Tooltip placement="right" title="Please enter 0 for the unlimited UEBA User.">
                <Input.Group compact>
                  <InputNumber
                    defaultValue={0}
                    style={{ width: '100px' }}
                    min={0}
                    placeholder="UEBA User Limit"
                    onChange={(value) => {
                      setAddSerialData((serial) => {
                        return { ...serial, ueba_user_limit: value };
                      });
                    }}
                  />
                </Input.Group>
              </Tooltip>
            </Col>
          </Row>
        )}
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Product Code:{" "}
          </Col>
          <Col span={12}>
            <Input
              placeholder="Product Code"
              status={validInputRegex.test(addSerialData?.product_code) ? "" : "error"}
              onChange={(e) => {
                setAddSerialData((serial) => {
                  return { ...serial, product_code: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Company Name:{" "}
          </Col>
          <Col span={12}>
            <Input
              status={validInputRegex.test(addSerialData?.company_name) ? "" : "error"}
              placeholder="Company Name"
              onChange={(e) => {
                setAddSerialData((serial) => {
                  return { ...serial, company_name: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Sales Owner:{" "}
          </Col>
          <Col>
            <Input.Group compact>
              <Select
                style={{ width: '235px' }}
                placeholder="Select a sales owner"
                onChange={(sales_owner_id) => {
                  setAddSerialData((serial) => {
                    return { ...serial, sales_owner_id: sales_owner_id };
                  });
                }}
              >
                {salesOwners.map((sales_owner) => {
                  return (
                    <Option key={sales_owner.id} value={sales_owner.id}>
                      {sales_owner.name + " " + sales_owner.surname}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Partner:{" "}
          </Col>
          <Col span={12} style={{ marginBottom: "5px" }}>
            <Input.Group compact>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '235px' }}
                placeholder="Select a partner"
                onChange={(partner_id) => {
                  setAddSerialData((serial) => {
                    return { ...serial, partner_id: partner_id };
                  });
                }}
              >
                {partners.map((partner) => {
                  return (
                    <Option key={partner.id} value={partner.id}>
                      {partner.name}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Note:{" "}
          </Col>
          <Col span={12}>
            <Input.TextArea
              placeholder="Notes"
              status={validInputRegex.test(addSerialData?.note) ? "" : "error"}
              onChange={(e) => {
                setAddSerialData((serial) => {
                  return { ...serial, note: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SerialList;
