import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getSalesOwners,
    addSalesOwner,
    deleteSalesOwner,
    updateSalesOwner,
} from "../../redux/actions/salesOwner";

import {
    Table,
    Input,
    Button,
    Modal,
    Col,
    Row,
    PageHeader,
} from "antd";
import {
    EditOutlined,
    DeleteOutlined,
    UserAddOutlined,
} from "@ant-design/icons";
import { isAdmin, PAGINATE_LIMIT } from "../common/Constants";
import Filter from "../Filter";


const SalesOwnerList = () => {
    const dispatch = useDispatch();
    const salesOwnerList = useSelector((state) => state.sales_owner.salesOwnerList);
    const totalSalesOwnerCount = useSelector((state) => state.sales_owner.totalSalesOwnerCount);
    const accessToken = useSelector((state) => state.access_token.accessToken);
    const userRole = useSelector((state) => state.access_token.userRole);

    const [isEdit, setIsEdit] = useState(false);
    const [editSalesOwner, setEditSalesOwner] = useState(null);
    const [addSalesOwnerData, setAddSalesOwnerData] = useState(null);
    const [isAddSalesOwner, setIsAddSalesOwner] = useState(false);
    const [currentPage, setCurrentPage] = useState({
        offset: 0,
        limit: PAGINATE_LIMIT,
    });
    const title = "Sales Owner";

    useEffect(() => {
        setTimeout(() => {
            dispatch(getSalesOwners(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.email));
        }, 100);
    }, [currentPage, dispatch, isEdit, isAddSalesOwner, accessToken]);

    const handleFilter = useCallback(e => {
        currentPage.email = e
        setCurrentPage(currentPage)
        dispatch(getSalesOwners(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.email));
    }, [])

    const onPaginateChange = (page, _) => {
        let newOffset = page === 1 ? 0 : (page - 1) * PAGINATE_LIMIT
        setCurrentPage({ offset: newOffset, limit: PAGINATE_LIMIT, email: currentPage?.email })
    };

    const onDeleteSalesOwner = (record) => {
        Modal.confirm({
            title: "Are you sure, you want to delete sales owner record?",
            okText: "Yes",
            okType: "danger",
            onOk: () => {
                dispatch(deleteSalesOwner(accessToken, record.id));
                setTimeout(() => {
                    dispatch(
                        getSalesOwners(accessToken, currentPage?.limit, currentPage?.offset)
                    );
                }, 100);
            },
        });
    };

    const onEditSalesOwner = (record) => {
        setIsEdit(true);
        setEditSalesOwner({ ...record });
    };

    const resetEditing = () => {
        setIsEdit(false);
        setEditSalesOwner(null);
    };

    const resetAddSalesOwner = () => {
        setIsAddSalesOwner(false);
        setAddSalesOwnerData(null);
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "Surname",
            dataIndex: "surname",
            key: "surname",
            align: "center",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            align: "center",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => {
                return (
                    isAdmin(userRole) && (
                        <>
                            <EditOutlined
                                onClick={() => {
                                    onEditSalesOwner(record);
                                }}
                            />
                            <DeleteOutlined
                                onClick={() => {
                                    onDeleteSalesOwner(record);
                                }}
                                style={{ color: "red", marginLeft: 12 }}
                            />
                        </>
                    )
                );
            },
        },
    ];

    return (
        <>
            <Filter name={"SalesOwnerList"} onFilter={handleFilter} />
            <PageHeader
                className="site-page-header"
                style={{ padding: "8px 0px" }}
                title={title}
                extra={[
                    <Button
                        key={"AddSalesOwner"}
                        type="primary"
                        icon={<UserAddOutlined />}
                        onClick={() => {
                            setIsAddSalesOwner(true);
                        }}
                    >
                        Add Sales Owner
                    </Button>,
                ]}
            />

            <Table
                rowKey={"id"}
                columns={columns}
                dataSource={salesOwnerList}
                bordered={true}
                tableLayout={"auto"}
                pagination={{
                    defaultPageSize: PAGINATE_LIMIT,
                    total: totalSalesOwnerCount,
                    onChange: onPaginateChange
                }}
            />
            <Modal
                title="Edit Sales Owner"
                visible={isEdit}
                onCancel={() => {
                    resetEditing();
                }}
                okText="Save"
                onOk={() => {
                    dispatch(updateSalesOwner(accessToken, editSalesOwner));
                    resetEditing();
                }}
            >
                <Row>
                    <Col span={6} style={{ margin: "10px" }}>
                        Name:{" "}
                    </Col>
                    <Col>
                        <Input
                            placeholder="Name"
                            value={editSalesOwner?.name}
                            onChange={(e) => {
                                setEditSalesOwner((sales_owner) => {
                                    return { ...sales_owner, name: e.target.value };
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{ margin: "10px" }}>
                        Surname:{" "}
                    </Col>
                    <Col>
                        <Input
                            placeholder="Surname"
                            value={editSalesOwner?.surname}
                            onChange={(e) => {
                                setEditSalesOwner((sales_owner) => {
                                    return { ...sales_owner, surname: e.target.value };
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{ margin: "10px" }}>
                        Email:{" "}
                    </Col>
                    <Col>
                        <Input
                            placeholder="Email"
                            value={editSalesOwner?.email}
                            onChange={(e) => {
                                setEditSalesOwner((sales_owner) => {
                                    return { ...sales_owner, email: e.target.value };
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="Add New Sales Owner"
                visible={isAddSalesOwner}
                onCancel={() => {
                    resetAddSalesOwner();
                }}
                okText="Save"
                onOk={() => {
                    dispatch(addSalesOwner(accessToken, addSalesOwnerData));
                    resetAddSalesOwner();
                }}
            >
                <Row>
                    <Col span={6} style={{ margin: "10px" }}>
                        Name:{" "}
                    </Col>
                    <Col>
                        <Input
                            placeholder="Name"
                            value={addSalesOwnerData?.name}
                            onChange={(e) => {
                                setAddSalesOwnerData((sales_owner) => {
                                    return { ...sales_owner, name: e.target.value };
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{ margin: "10px" }}>
                        Surname:{" "}
                    </Col>
                    <Col>
                        <Input
                            placeholder="Surname"
                            value={addSalesOwnerData?.surname}
                            onChange={(e) => {
                                setAddSalesOwnerData((sales_owner) => {
                                    return { ...sales_owner, surname: e.target.value };
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{ margin: "10px" }}>
                        Email:{" "}
                    </Col>
                    <Col>
                        <Input
                            placeholder="Email"
                            value={addSalesOwnerData?.email}
                            onChange={(e) => {
                                setAddSalesOwnerData((sales_owner) => {
                                    return { ...sales_owner, email: e.target.value };
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default SalesOwnerList;
