import {
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
  GET_USERS,
} from '../actions/types';

const USER_INITIAL_STATE = {
  userList: [],
  totalUserCount: 0,
};

const userCrudReducer = (state = USER_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        userList: action.payload.users,
        totalUserCount: action.payload.totalUserCount,
      };
    case ADD_USER:
      return { ...state, user: action.payload };
    case DELETE_USER:
      return {
        ...state,
        userList: state.userList.filter((user) => user !== action.payload),
      };
    case UPDATE_USER:
      return {
        ...state,
        userList: state.userList.filter(
          (user) => user.id === action.payload.id
        ),
      };
    default:
      return state;
  }
};

export default userCrudReducer;
