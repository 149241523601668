import { DELETE_LICENSE, GET_LICENSES, UPDATE_LICENSE } from '../actions/types';

const LICENSE_INITIAL_STATE = {
  licenseList: [],
  totalSiemLicenseCount: 0,
};

const licenseReducer = (state = LICENSE_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LICENSES:
      return {
        ...state,
        licenseList: action.payload.siem_licenses,
        totalSiemLicenseCount: action.payload.totalSiemLicenseCount,
      };
    case UPDATE_LICENSE:
      return {
        ...state,
        licenseList: state.licenseList.filter(
          (license) => license.id === action.payload.id
        ),
      };
    case DELETE_LICENSE:
      return {
        ...state,
        licenseList: state.licenseList.filter(
          (license) => license !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default licenseReducer;
