export const licenseStatusOptions = [
  {
    value: "Active",
    label: "active",
  },
  {
    value: "Inactive",
    label: "inactive",
  },
  {
    value: "Expired",
    label: "expired",
  },
];

export const userRoleOptions = [
  {
    value: "admin",
    label: "admin",
  },
  {
    value: "moderator",
    label: "moderator",
  },
];

export const supportStatusOptions = [
  {
    value: "Active",
    label: "active",
  },
  {
    value: "Inactive",
    label: "inactive",
  },
  {
    value: "Expired",
    label: "expired",
  },
];

export const booleanOptions = [
  {
    value: "True",
    label: "true",
  },
  {
    value: "False",
    label: "false",
  },
];

export const isAdmin = (role) => {
  return role === "admin" ? true : false;
};

export const PAGINATE_LIMIT = 10;
export const PAGE_NOT_FOUND = "Page not found.";
