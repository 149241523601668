import {
  DELETE_SIEM_SERIAL,
  UPDATE_SIEM_SERIAL,
  ADD_SIEM_SERIAL,
  GET_SIEM_SERIALS,
} from '../actions/types';

const SERIAL_INITIAL_STATE = {
  serialList: [],
  totalSiemSerialCount: 0,
};

const serialReducer = (state = SERIAL_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SIEM_SERIALS:
      return {
        ...state,
        serialList: action.payload.siem_serials,
        totalSiemSerialCount: action.payload.totalSiemSerialCount,
      };
    case ADD_SIEM_SERIAL:
      return { ...state, serial: action.payload };
    case DELETE_SIEM_SERIAL:
      return {
        ...state,
        serialList: state.serialList.filter(
          (serial) => serial !== action.payload
        ),
      };
    case UPDATE_SIEM_SERIAL:
      return {
        ...state,
        serialList: state.serialList.filter(
          (serial) => serial.id === action.payload.id
        ),
      };
    default:
      return state;
  }
};

export default serialReducer;
