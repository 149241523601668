export const GET_LICENSES = "GET_LICENSES";
export const UPDATE_LICENSE = "UPDATE_LICENSE";
export const DELETE_LICENSE = "DELETE_LICENSE";
export const GET_LICENSES_ERR = "GET_LICENSES_ERR";
export const UPDATE_LICENSE_ERR = "UPDATE_LICENSE_ERR";
export const DELETE_LICENSE_ERR = "DELETE_LICENSE_ERR";

export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_USERS_ERR = "GET_USERS_ERR";
export const ADD_USER_ERR = "ADD_USER_ERR";
export const UPDATE_USER_ERR = "UPDATE_USER_ERR";
export const DELETE_USER_ERR = "DELETE_USER_ERR";

export const GET_SALES_OWNERS = "GET_GET_SALES_OWNERS";
export const ADD_SALES_OWNER = "ADD_SALES_OWNER";
export const UPDATE_SALES_OWNER = "UPDATE_SALES_OWNER";
export const DELETE_SALES_OWNER = "DELETE_SALES_OWNER";
export const GET_SALES_OWNER_ERR = "GET_SALES_OWNER_ERR";
export const ADD_SALES_OWNER_ERR = "ADD_SALES_OWNER_ERR";
export const UPDATE_SALES_OWNER_ERR = "UPDATE_SALES_OWNER_ERR";
export const DELETE_SALES_OWNER_ERR = "DELETE_SALES_OWNER_ERR";

export const GET_SIEM_SERIALS = "GET_SERIALS";
export const GET_SIEM_SERIAL_DETAIL = "GET_SERIAL_DETAIL";
export const ADD_SIEM_SERIAL = "ADD_SERIAL";
export const UPDATE_SIEM_SERIAL = "UPDATE_SERIAL";
export const DELETE_SIEM_SERIAL = "DELETE_SERIAL";
export const GET_SIEM_SERIALS_ERR = "GET_SERIALS_ERR";
export const GET_SIEM_SERIAL_DETAIL_ERR = "GET_SERIAL_DETAIL_ERR";
export const ADD_SIEM_SERIAL_ERR = "ADD_SERIAL_ERR";
export const UPDATE_SIEM_SERIAL_ERR = "UPDATE_SERIAL_ERR";
export const DELETE_SIEM_SERIAL_ERR = "DELETE_SERIAL_ERR";

export const GET_PARTNERS = "GET_PARTNERS";
export const ADD_PARTNER = "ADD_PARTNER";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const DELETE_PARTNER = "DELETE_PARTNER";
export const GET_PARTNERS_ERR = "GET_PARTNERS_ERR";
export const ADD_PARTNER_ERR = "ADD_PARTNER_ERR";
export const UPDATE_PARTNER_ERR = "UPDATE_PARTNER_ERR";
export const DELETE_PARTNER_ERR = "DELETE_PARTNER_ERR";

export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_USER_ROLE = "GET_USER_ROLE";
export const LOGOUT_FOR_BACKEND = "LOGOUT_FOR_BACKEND";
