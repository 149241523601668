import React from "react";
import { googleLogout } from '@react-oauth/google';
import { useSelector, useDispatch } from "react-redux";
import { selectSignedIn, selectUserData, setSignedIn, setUserData } from "../../redux/reducers/auth";

import { Menu, Button } from "antd";

const HeaderPage = () => {
  const dispatch = useDispatch();

  const isSignedIn = useSelector(selectSignedIn);
  const userData = useSelector(selectUserData);

  const logout = () => {
    dispatch(setSignedIn(false));
    dispatch(setUserData(null));
    removeAccessTokenFromLocalStora();
    googleLogout();
  };

  const removeAccessTokenFromLocalStora = () => {
    localStorage.removeItem("access_token");
  };

  return (
    <>
      {isSignedIn ? (
        <>
          <Menu.Item key="1" style={{ marginLeft: "auto" }} disabled>
            {userData?.name}
          </Menu.Item>
          <Menu.Item key="2">
            <Button onClick={logout}>Logout</Button>
          </Menu.Item>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default HeaderPage;
