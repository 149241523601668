import {
  GET_ACCESS_TOKEN,
  GET_USER_ROLE,
  LOGOUT_FOR_BACKEND,
} from '../actions/types';

const ACCESS_TOKEN_INITIAL_STATE = {
  accessToken: '',
  userRole: '',
};

const accessTokenReducer = (state = ACCESS_TOKEN_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case GET_USER_ROLE:
      return { ...state, userRole: action.payload };
    case LOGOUT_FOR_BACKEND:
      return { ...state, accessToken: '' };
    default:
      return state;
  }
};

export default accessTokenReducer;
