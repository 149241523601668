import { api } from "../../config/api";
import {
  GET_LICENSES,
  DELETE_LICENSE,
  UPDATE_LICENSE,
  GET_LICENSES_ERR,
  UPDATE_LICENSE_ERR,
  DELETE_LICENSE_ERR,
} from "./types";
import { PAGINATE_LIMIT } from "../../components/common/Constants";

import { toast } from "react-toastify";

export const getLicenses =
  (accessToken, limit = PAGINATE_LIMIT, offset = 0, filterStr) =>
    (dispatch) => {
      let url = filterStr ? `/siem_licenses?limit=${limit}&offset=${offset}${filterStr}` :
        `/siem_licenses?limit=${limit}&offset=${offset}`
      api(accessToken)
        .get(url)
        .then((response) => {
          dispatch({ type: GET_LICENSES, payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: GET_LICENSES_ERR, payload: error.message });
          if (error.response !== undefined) {
            toast.error(error.response.data.detail);
          }
        });
    };

export const updateLicenseWithID = (accessToken, newData) => (dispatch) => {
  api(accessToken)
    .put(`/siem_license/${newData.id}`, newData)
    .then((response) => {
      dispatch({ type: UPDATE_LICENSE, payload: response.data });
      toast.success("License successfully updated.");
    })
    .catch((error) => {
      dispatch({ type: UPDATE_LICENSE_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};

export const deleteLicenseWithID = (accessToken, id) => (dispatch) => {
  api(accessToken)
    .delete(`/siem_license/${id}`)
    .then(() => {
      dispatch({ type: DELETE_LICENSE, payload: id });
      toast.success("License successfully deleted.");
    })
    .catch((error) => {
      dispatch({ type: DELETE_LICENSE_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};
