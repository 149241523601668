import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
const { Option } = Select;


export const SerialListFilter = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.onFilter(values)
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      onFinish={onFinish}
    >
      <Row gutter={24} >
        <Col span={6} key="company_name">
          <Form.Item name="company_name" label="Customer">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6} key="hostid">
          <Form.Item name="hostid" label="HostID">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6} key="serial_key">
          <Form.Item name="serial_key" label="Serial">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6} key="note">
          <Form.Item name="note" label="Note">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} >
        <Col span={6} key="trial">
          <Form.Item name="trial" label="Trial">
            <Select>
              <Option value="1">YES</Option>
              <Option value="0">NO</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} key="support_status">
          <Form.Item name="support_status" label="Support Status">
            <Select>
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
              <Option value="Expired">Expired</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} key="license_status">
          <Form.Item name="license_status" label="License Status">
            <Select>
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
              <Option value="Expired">Expired</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} key="product_code">
          <Form.Item name="product_code" label="Product Code">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: 'right',
          }}
        >
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
          <Button htmlType="submit"
            type='dashed'
            style={{
              margin: '0 8px',
            }}
            icon={<ReloadOutlined />}
            onClick={() => {
              form.resetFields();
              props.onFilter({});

            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
