import axios from "axios";
import { GET_ACCESS_TOKEN, GET_USER_ROLE, LOGOUT_FOR_BACKEND } from "./types";
import { setSignedIn } from "../reducers/auth";

export const getAccessToken = (googleAccToken) => (dispatch) => {
  let loginUrl = `${process.env.REACT_APP_API_URL}/login`;
  axios
    .post(loginUrl, { access_token: googleAccToken })
    .then((response) => {
      dispatch({ type: GET_ACCESS_TOKEN, payload: response.data.access_token });
      dispatch({ type: GET_USER_ROLE, payload: response.data.role });
      dispatch(setSignedIn(true));
    })
    .catch((error) => {
      dispatch({
        type: "GET_ACCESS_TOKEN_ERROR",
        payload: "Error message",
      });
    });
};

export const logOut = (email) => (dispatch) => {
  let logOutUrl = `${process.env.REACT_APP_API_URL}/logout`;
  axios
    .post(logOutUrl, { email: email })
    .then((response) => {
      dispatch({
        type: LOGOUT_FOR_BACKEND,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_ACCESS_TOKEN_ERROR",
        payload: "Error message",
      });
    });
};
