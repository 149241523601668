import { Button, Col, Form, Input, Row } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';


export const SalesOwnerListFilter = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.onFilter(values)
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col key="email">
          <Form.Item name="email" label="Email Address">
            <Input />
          </Form.Item>
        </Col>
        <Col
          style={{
            textAlign: 'right',
          }}
        >
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
          <Button htmlType="submit"
            type='dashed'
            style={{
              margin: '0 8px',
            }}
            icon={<ReloadOutlined />}
            onClick={() => {
              form.resetFields();
              props.onFilter({});

            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
