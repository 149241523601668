import { Button, Col, Form, Input, Row } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';


export const LicenseListFilter = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        props.onFilter(values)
    };

    return (
        <Form
            form={form}
            name="advanced_search"
            onFinish={onFinish}
        >
            <Row gutter={24}>
                <Col span={8} key="hostid">
                    <Form.Item name="hostid" label="HostID">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8} key="serial_key">
                    <Form.Item name="serial_key" label="Serial">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8} key="customer">
                    <Form.Item name="customer" label="Customer">
                        <Input />
                    </Form.Item>
                </Col>
                <Col
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                    <Button htmlType="submit"
                        type='dashed'
                        style={{
                            margin: '0 8px',
                        }}
                        icon={<ReloadOutlined />}
                        onClick={() => {
                            form.resetFields();
                            props.onFilter({});

                        }}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
