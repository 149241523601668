import { SalesOwnerListFilter } from "./SalesOwnerListFilter";
import { SerialListFilter } from "./SerialListFilter";
import { LicenseListFilter } from "./LicenseListFilter";
import { UserListFilter } from "./UserListFilter";
import { PartnerListFilter } from "./PartnerListFilter";
import { Collapse } from 'antd';

const { Panel } = Collapse;


export const Filter = (props) => {
  const filteredPages = {
    "SalesOwnerList": SalesOwnerListFilter,
    "SerialList": SerialListFilter,
    "LicenseList": LicenseListFilter,
    "UserList": UserListFilter,
    "PartnerList": PartnerListFilter,
  }

  const FilterComp = filteredPages[props.name]
  return (
    <Collapse>
      <Panel header="Filter" key="1">
        <FilterComp {...props} />
      </Panel>
    </Collapse>
  );
};