import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUsers,
  addUser,
  deleteUserWithID,
  updateUserWithID,
} from "../../redux/actions/user";

import {
  Table,
  Input,
  Button,
  Modal,
  Col,
  Row,
  Select,
  PageHeader,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import Filter from "../Filter";
import { userRoleOptions, isAdmin, PAGINATE_LIMIT } from "../common/Constants";

const { Option } = Select;

const UserList = () => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.user.userList);
  const totalUserCount = useSelector((state) => state.user.totalUserCount);
  const accessToken = useSelector((state) => state.access_token.accessToken);
  const userRole = useSelector((state) => state.access_token.userRole);

  const [isEdit, setIsEdit] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [addUserData, setAddUserData] = useState(null);
  const [isAddUser, setIsAddUser] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    offset: 0,
    limit: PAGINATE_LIMIT,
  });
  const title = "Users";
  let filterStr = ``;

  useEffect(() => {
    setTimeout(() => {
      dispatch(getUsers(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
    }, 100);
  }, [currentPage, dispatch, isEdit, isAddUser, accessToken]);

  const onDeleteUser = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete user record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(deleteUserWithID(accessToken, record.id));
        setTimeout(() => {
          dispatch(
            getUsers(accessToken, currentPage?.limit, currentPage?.offset)
          );
        }, 100);
      },
    });
  };

  const onEditUser = (record) => {
    setIsEdit(true);
    setEditUser({ ...record });
  };

  const resetEditing = () => {
    setIsEdit(false);
    setEditUser(null);
  };

  const resetAddUser = () => {
    setIsAddUser(false);
    setAddUserData(null);
  };

  const handleFilter = useCallback(filterData => {
    filterStr = ``;
    Object.keys(filterData).map((key) => {
      filterData[key] && (filterStr = filterStr + `&${key}=${filterData[key]}`)
    })
    currentPage.filterStr = filterStr
    setCurrentPage(currentPage)
    dispatch(getUsers(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
  }, [])

  const onPaginateChange = (page, _) => {
    let newOffset = page === 1 ? 0 : (page - 1) * PAGINATE_LIMIT
    setCurrentPage({ offset: newOffset, limit: PAGINATE_LIMIT, filterStr: currentPage?.filterStr })
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => {
        return (
          isAdmin(userRole) && (
            <>
              <EditOutlined
                onClick={() => {
                  onEditUser(record);
                }}
              />
              <DeleteOutlined
                onClick={() => {
                  onDeleteUser(record);
                }}
                style={{ color: "red", marginLeft: 12 }}
              />
            </>
          )
        );
      },
    },
  ];

  return (
    <>
      <Filter name={"UserList"} onFilter={handleFilter} />
      <PageHeader
        className="site-page-header"
        style={{ padding: "8px 0px" }}
        title={title}
        extra={[
          <Button
            key={"AddUser"}
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => {
              setIsAddUser(true);
            }}
          >
            Add User
          </Button>,
        ]}
      />

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={userList}
        bordered={true}
        tableLayout={"auto"}
        pagination={{
          defaultPageSize: PAGINATE_LIMIT,
          total: totalUserCount,
          onChange: onPaginateChange
        }}
      />
      <Modal
        title="Edit User"
        visible={isEdit}
        onCancel={() => {
          resetEditing();
        }}
        okText="Save"
        onOk={() => {
          dispatch(updateUserWithID(accessToken, editUser));
          resetEditing();
        }}
      >
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Username:{" "}
          </Col>
          <Col>
            <Input
              placeholder="Username"
              value={editUser?.username}
              onChange={(e) => {
                setEditUser((user) => {
                  return { ...user, username: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Role:{" "}
          </Col>
          <Col>
            <Input.Group compact>
              <Select
                placeholder="Select a role"
                value={editUser?.role}
                onChange={(value) => {
                  setEditUser((user) => {
                    return { ...user, role: value };
                  });
                }}
              >
                {userRoleOptions.map((role) => {
                  return (
                    <Option key={role.label} value={role.value}>
                      {role.value}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
            <br />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Add New User"
        visible={isAddUser}
        onCancel={() => {
          resetAddUser();
        }}
        okText="Save"
        onOk={() => {
          dispatch(addUser(accessToken, addUserData));
          resetAddUser();
        }}
      >
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Username:{" "}
          </Col>
          <Col>
            <Input
              placeholder="Username"
              value={addUserData?.username}
              onChange={(e) => {
                setAddUserData((user) => {
                  return { ...user, username: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Role:{" "}
          </Col>
          <Col>
            <Input.Group compact>
              <Select
                placeholder="Select a role"
                value={addUserData?.role}
                onChange={(value) => {
                  setAddUserData((user) => {
                    return { ...user, role: value };
                  });
                }}
              >
                {userRoleOptions.map((role) => {
                  return (
                    <Option key={role.label} value={role.value}>
                      {role.value}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
            <br />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UserList;
