import { api } from "../../config/api";
import { PAGINATE_LIMIT } from "../../components/common/Constants";
import {
  GET_PARTNERS,
  DELETE_PARTNER,
  UPDATE_PARTNER,
  ADD_PARTNER,
  GET_PARTNERS_ERR,
  ADD_PARTNER_ERR,
  DELETE_PARTNER_ERR,
  UPDATE_PARTNER_ERR,
} from "./types";

import { toast } from "react-toastify";

export const getPartner =
  (accessToken, limit = PAGINATE_LIMIT, offset = 0, filterStr) =>
    (dispatch) => {
      let url = filterStr ? `/partners?limit=${limit}&offset=${offset}${filterStr}` :
        `/partners?limit=${limit}&offset=${offset}`
      api(accessToken)
        .get(url)
        .then((response) => {
          dispatch({ type: GET_PARTNERS, payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: GET_PARTNERS_ERR, payload: error.message });
          if (error.response !== undefined) {
            toast.error(error.response.data.detail);
          }
        });
    };

export const addPartner = (accessToken, data) => (dispatch) => {
  api(accessToken)
    .post("/partner", data)
    .then((response) => {
      dispatch({ type: ADD_PARTNER, payload: response.data });
      toast.success("Partner successfully added.");
    })
    .catch((error) => {
      dispatch({ type: ADD_PARTNER_ERR, payload: error.message });
      if (error.response.data) {
        if (error.response !== undefined) {
          toast.error(error.response.data.detail);
        }
      }
    });
};

export const updatePartner = (accessToken, newData) => (dispatch) => {
  api(accessToken)
    .put(`/partner/${newData.id}`, newData)
    .then((response) => {
      dispatch({ type: UPDATE_PARTNER, payload: response.data });
      toast.success("Partner successfully updated.");
    })
    .catch((error) => {
      dispatch({ type: UPDATE_PARTNER_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};

export const deletePartner = (accessToken, id) => (dispatch) => {
  api(accessToken)
    .delete(`/partner/${id}`)
    .then(() => {
      dispatch({ type: DELETE_PARTNER, payload: id });
      toast.success("Partner successfully deleted.");
    })
    .catch((error) => {
      dispatch({ type: DELETE_PARTNER_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};
