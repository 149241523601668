import {
  DELETE_PARTNER,
  UPDATE_PARTNER,
  ADD_PARTNER,
  GET_PARTNERS,
} from '../actions/types';

const PARTNER_INITIAL_STATE = {
  partnerList: [],
  totalPartnerCount: 0,
};

const partnerReducer = (state = PARTNER_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload.partners,
        totalPartnerCount: action.payload.totalPartnerCount,
      };
    case ADD_PARTNER:
      return { ...state, partner: action.payload };
    case DELETE_PARTNER:
      return {
        ...state,
        partnerList: state.partnerList.filter(
          (partner) => partner !== action.payload
        ),
      };
    case UPDATE_PARTNER:
      return {
        ...state,
        partnerList: state.partnerList.filter(
          (partner) => partner.id === action.payload.id
        ),
      };
    default:
      return state;
  }
};

export default partnerReducer;
