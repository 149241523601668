import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPartner, addPartner, deletePartner, updatePartner } from "../../redux/actions/partner";

import {Table, Input, Button, Modal, Col, Row, PageHeader, Switch, Tag} from "antd";
import {EditOutlined, DeleteOutlined, DollarCircleOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import { isAdmin, PAGINATE_LIMIT } from "../common/Constants";
import Filter from "../Filter";


const PartnerList = () => {
  const dispatch = useDispatch();
  const partnerList = useSelector((state) => state.partner.partnerList);
  const totalPartnerCount = useSelector((state) => state.partner.totalPartnerCount);
  const accessToken = useSelector((state) => state.access_token.accessToken);
  const userRole = useSelector((state) => state.access_token.userRole);
  const [currentPage, setCurrentPage] = useState({
    offset: 0,
    limit: PAGINATE_LIMIT,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editPartner, setEditPartner] = useState(null);
  const [addPartnerData, setAddPartnerData] = useState(null);
  const [isAddPartner, setIsAddPartner] = useState(false);
  const title = "Partners";
  let filterStr = ``;


  useEffect(() => {
    setTimeout(() => {
      dispatch(getPartner(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
    }, 400);
  }, [currentPage, accessToken, dispatch, isEdit, isAddPartner]);


  const onDeletePartner = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete partner record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(deletePartner(accessToken, record.id));
        setTimeout(() => {
          dispatch(getPartner(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
        }, 100);
      },
    });
  };

  const onEditPartner = (record) => {
    setIsEdit(true);
    setEditPartner({ ...record });
  };

  const resetEditing = () => {
    setIsEdit(false);
    setEditPartner(null);
  };

  const resetAddPartner = () => {
    setIsAddPartner(false);
    setAddPartnerData(null);
  };

  const handleFilter = useCallback(filterData => {
    filterStr = ``;
    Object.keys(filterData).map((key) => {
      filterData[key] && (filterStr = filterStr + `&${key}=${filterData[key]}`)
    })
    currentPage.filterStr = filterStr
    setCurrentPage(currentPage)
    dispatch(getPartner(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
  }, [])

  const onPaginateChange = (page, _) => {
    let newOffset = page === 1 ? 0 : (page - 1) * PAGINATE_LIMIT
    setCurrentPage({ offset: newOffset, limit: PAGINATE_LIMIT, filterStr: currentPage?.filterStr })
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center"
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center"
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center"
    },
    {
      title: "Global",
      dataIndex: "global_",
      key: "global",
      align: "center",
      render: (global) => (global ? <Tag color="green"> YES </Tag > : <Tag color="red"> NO </Tag >),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => {
        return (
          isAdmin(userRole) && (
            <>
              <EditOutlined
                onClick={() => {
                  onEditPartner(record);
                }}
              />
              <DeleteOutlined
                onClick={() => {
                  onDeletePartner(record);
                }}
                style={{ color: "red", marginLeft: 12 }}
              />
            </>
          )
        );
      },
    },
  ];

  return (
    <>
      <Filter name={"PartnerList"} onFilter={handleFilter} />
      <PageHeader
        className="site-page-header"
        style={{ padding: "8px 0px" }}
        title={title}
        extra={[
          <Button
            key={"addPartner"}
            type="primary"
            icon={<DollarCircleOutlined />}
            onClick={() => {
              setIsAddPartner(true);
            }}
          >
            Add Partner
          </Button>,
        ]}
      />

      <Table
        rowKey={(partner) => partner.id}
        key={"id"}
        columns={columns}
        dataSource={partnerList}
        bordered={true}
        tableLayout={"auto"}
        pagination={{
          defaultPageSize: PAGINATE_LIMIT,
          total: totalPartnerCount,
          onChange: onPaginateChange
        }}
      />
      <Modal
        title="Edit Partner"
        visible={isEdit}
        onCancel={() => {
          resetEditing();
        }}
        okText="Save"
        onOk={() => {
          dispatch(updatePartner(accessToken, editPartner));
          resetEditing();
        }}
      >
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Name:{" "}
          </Col>
          <Col>
            <Input
              placeholder="Name"
              value={editPartner?.name}
              onChange={(e) => {
                setEditPartner((partner) => {
                  return { ...partner, name: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Address:{" "}
          </Col>
          <Col>
            <Input.TextArea
              placeholder="Address"
              value={editPartner?.address}
              onChange={(e) => {
                setEditPartner((partner) => {
                  return { ...partner, address: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Email:{" "}
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Input
              placeholder="Email"
              value={editPartner?.email}
              onChange={(e) => {
                setEditPartner((partner) => {
                  return { ...partner, email: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Phone:{" "}
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Input
              placeholder="Phone"
              value={editPartner?.phone}
              onChange={(e) => {
                setEditPartner((partner) => {
                  return { ...partner, phone: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Global:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={editPartner?.global_}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setEditPartner((partner) => {
                    return { ...partner, global_: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Add New Partner"
        visible={isAddPartner}
        onCancel={() => {
          resetAddPartner();
        }}
        okText="Save"
        onOk={() => {
          dispatch(addPartner(accessToken, addPartnerData));
          resetAddPartner();
        }}
      >
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Name:{" "}
          </Col>
          <Col>
            <Input
              placeholder="Name"
              value={addPartnerData?.name}
              onChange={(e) => {
                setAddPartnerData((partner) => {
                  return { ...partner, name: e.target.value };
                });
              }}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Address:{" "}
          </Col>
          <Col>
            <Input.TextArea
              placeholder="Address"
              value={addPartnerData?.address}
              onChange={(e) => {
                setAddPartnerData((partner) => {
                  return { ...partner, address: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Email:{" "}
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Input
              placeholder="Email"
              value={addPartnerData?.email}
              onChange={(e) => {
                setAddPartnerData((partner) => {
                  return { ...partner, email: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ margin: "10px" }}>
            Phone:{" "}
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Input
              placeholder="Phone"
              value={addPartnerData?.phone}
              onChange={(e) => {
                setAddPartnerData((partner) => {
                  return { ...partner, phone: e.target.value };
                });
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6} style={{ margin: "10px" }}>
            Global:{" "}
          </Col>
          <Col>
            <Input.Group size="large">
              <Switch
                checked={addPartnerData?.global_}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => {
                  setAddPartnerData((partner) => {
                    return { ...partner, global_: checked };
                  });
                }}
              />
            </Input.Group>
          </Col>
        </Row>
      </Modal>
    </>
  );
};


export default PartnerList;
