import React, { useState } from "react";
import { useSelector } from "react-redux";
import HeaderPage from "../Logout/LogoutPage";
import FooterPage from "../Footer/FooterPage";
import { Layout, Menu } from "antd";
import { categories } from "../common/Categories";

const { Header, Content } = Layout;

const HomePage = () => {
  const userRole = useSelector((state) => state.access_token.userRole);
  const [currentCategory, setCurrentCategory] = useState(categories[0]);

  return (
    <Layout style={{ backgroundColor: "#192c3e", minHeight: "100vh" }}>
      <Header>
        <Menu
          mode="horizontal"
          theme="dark"
          defaultSelectedKeys={["0"]}
          onClick={(cat) => {
            setCurrentCategory(categories[cat.key]);
          }}
          style={{ height: "100%", borderRight: 0 }}
        >
          {categories.map((cat) => {
            return cat.permissions.includes(userRole) ? (
              <Menu.Item key={cat.id} icon={cat.icon}>
                {cat.name}
              </Menu.Item>
            ) : null;
          })}
          <HeaderPage />
        </Menu>
      </Header>

      <Layout>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {currentCategory?.component}
          </Content>
        </Layout>
      </Layout>
      <FooterPage />
    </Layout>
  );
};

export default HomePage;
