import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteLicenseWithID,
  getLicenses,
} from "../../redux/actions/license";

import {
  Table,
  Button,
  Modal,
  PageHeader,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
} from "@ant-design/icons";

import {
  isAdmin,
  PAGINATE_LIMIT,
} from "../common/Constants";
import Filter from "../Filter";


const LicenseList = () => {
  const dispatch = useDispatch();
  const licenseList = useSelector((state) => state.license.licenseList);
  const totalSiemLicenseCount = useSelector(
    (state) => state.license.totalSiemLicenseCount
  );
  const [currentPage, setCurrentPage] = useState({
    offset: 0,
    limit: PAGINATE_LIMIT,
  });
  const accessToken = useSelector((state) => state.access_token.accessToken);
  const userRole = useSelector((state) => state.access_token.userRole);
  const title = "Licenses";
  let filterStr = ``;

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        getLicenses(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr)
      );
    }, 100);
  }, [currentPage, dispatch, accessToken]);

  const onDeleteLicense = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete license record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(deleteLicenseWithID(accessToken, record.id));
        setTimeout(() => {
          dispatch(
            getLicenses(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr)
          );
        }, 100);
      },
    });
  };


  const handleFilter = useCallback(filterData => {
    filterStr = ``;
    Object.keys(filterData).map((key) => {
      filterData[key] && (filterStr = filterStr + `&${key}=${filterData[key]}`)
    })
    currentPage.filterStr = filterStr
    setCurrentPage(currentPage)
    dispatch(getLicenses(accessToken, currentPage?.limit, currentPage?.offset, currentPage?.filterStr));
  }, [])

  const onPaginateChange = (page, _) => {
    let newOffset = page === 1 ? 0 : (page - 1) * PAGINATE_LIMIT
    setCurrentPage({ offset: newOffset, limit: PAGINATE_LIMIT, filterStr: currentPage?.filterStr })
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "company_name",
      key: "company_name",
      align: "center"
    },
    {
      title: "Create Time",
      dataIndex: "create_time",
      key: "create_time",
      align: "center",
      render: (create_time) => create_time.replace("T", " ")
    },
    {
      title: "Sync Time",
      dataIndex: "sync_time",
      key: "sync_time",
      align: "center",
      render: (sync_time) => sync_time.replace("T", " ")
    },
    {
      title: "Host ID",
      dataIndex: "hostid",
      key: "hostid",
      align: "center",
      render: (hostid) => (
        <Button
          type="dashed"
          onClick={() => {
            navigator.clipboard.writeText(hostid);
          }}
        >
          <Tooltip title="Click & Copy Me">
            {hostid}
          </Tooltip>
        </Button>
      ),
    },
    {
      title: "Serial Key",
      dataIndex: "serial_key",
      key: "serial_key",
      align: "center",
      render: (serial_key) => (
        <Button
          type="dashed"
          onClick={() => {
            navigator.clipboard.writeText(serial_key);
          }}
        >
          <Tooltip title="Click & Copy Me">
            {serial_key}
          </Tooltip>
        </Button>
      ),
    },
    {
      title: "License Code",
      dataIndex: "license_code",
      key: "license_code",
      align: "center",
      tags: ["LICENSE CODE"],
      ellipsis: {
        showTitle: true,
      },
      render: (license_code) => (
        <Button
          type="dashed"
          danger
          onClick={() => {
            navigator.clipboard.writeText(license_code);
          }}
        >
          Copy License Code
        </Button>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => {
        return (
          isAdmin(userRole) && (
            <>
              <DeleteOutlined
                onClick={() => {
                  onDeleteLicense(record);
                }}
                style={{ color: "red", marginLeft: 15 }}
              />
            </>
          )
        );
      },
    },
  ];

  return (
    <>
      <Filter name={"LicenseList"} onFilter={handleFilter} />
      <PageHeader
        style={{ padding: "8px 0px" }}
        className="site-page-header"
        title={title} />
      <Table
        rowKey={(license) => license.id}
        columns={columns}
        dataSource={licenseList}
        bordered={true}
        tableLayout={"auto"}
        pagination={{
          defaultPageSize: PAGINATE_LIMIT,
          total: totalSiemLicenseCount,
          onChange: onPaginateChange
        }}
      />
    </>
  );
};

export default LicenseList;
