import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isSignedIn: false,
    userData: null,
    userRole: null,
  },
  reducers: {
    setSignedIn: (state, action) => {
      state.isSignedIn = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setSignedIn, setUserData, setUserRole } = userSlice.actions;

export const selectSignedIn = (state) => state.auth.isSignedIn;
export const selectUserData = (state) => state.auth.userData;
export const selectUserRole = (state) => state.auth.userRole;

export default userSlice.reducer;
