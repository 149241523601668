import {
  TeamOutlined,
  LockOutlined,
  BarcodeOutlined,
  DollarCircleOutlined,
  GiftOutlined
} from '@ant-design/icons';
import LicenseList from '../License/LicenseList';
import SerialList from '../Serial/SerialList';
import UserList from '../User/UserList';
import SalesOwnerList from '../SalesOwner/SalesOwnerList';
import PartnerList from '../Partner/PartnerList';

export const categories = [
  {
    id: 0,
    name: 'Serials',
    icon: <LockOutlined />,
    link: '/siem_serials',
    component: <SerialList />,
    permissions: ['admin', 'moderator'],
  },
  {
    id: 1,
    name: 'Licenses',
    icon: <BarcodeOutlined />,
    link: '/siem_licenses',
    component: <LicenseList />,
    permissions: ['admin', 'moderator'],
  },
  {
    id: 2,
    name: 'Sales Owner',
    icon: <GiftOutlined />,
    link: '/sales_owner',
    component: <SalesOwnerList />,
    permissions: ['admin'],
  },
  {
    id: 3,
    name: 'Partners',
    icon: <DollarCircleOutlined />,
    link: '/partners',
    component: <PartnerList />,
    permissions: ['admin', 'moderator'],
  },
  {
    id: 4,
    name: 'Users',
    icon: <TeamOutlined />,
    link: '/users',
    component: <UserList />,
    permissions: ['admin'],
  },
];
