import React from 'react';
import {Dropdown, Menu, Button} from 'antd';
import {ExportOutlined} from "@ant-design/icons";


export const Exporter = (props) => {
    const onClick = (e) => {
      props.onExport(e.key)
    };

    const menu = (
      <>
        <Menu onClick={onClick}>
          <Menu.Item key={"csv"}>CSV</Menu.Item>
          <Menu.Item key={"excel"}>Excel</Menu.Item>
        </Menu>
      </>
    );
    return (
      <Dropdown overlay={menu}>
        <Button
          key={"Export"}
          icon={<ExportOutlined/>}
          loading={props.loading}
        >
          Export
        </Button>
      </Dropdown>
    )
  }
;

