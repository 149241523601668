import { combineReducers } from 'redux';
import userReducer from './auth';
import licenseReducer from './license';
import userCrudReducer from './user';
import serialReducer from './serial';
import partnerReducer from './partner';
import salesOwnerReducer from './salesOwner';
import accessTokenReducer from './accessToken';

const rootReducer = combineReducers({
  auth: userReducer,
  license: licenseReducer,
  user: userCrudReducer,
  serial: serialReducer,
  partner: partnerReducer,
  sales_owner: salesOwnerReducer,
  access_token: accessTokenReducer,
});

export default rootReducer;
