import { PAGINATE_LIMIT } from "../../components/common/Constants";
import { api } from "../../config/api";
import {
  GET_USERS,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
  ADD_USER_ERR,
  UPDATE_USER_ERR,
  DELETE_USER_ERR,
  GET_USERS_ERR,
} from "./types";
import { toast } from "react-toastify";

export const getUsers =
  (accessToken, limit = PAGINATE_LIMIT, offset = 0, filterStr) =>
    (dispatch) => {
      let url = filterStr ? `/users?limit=${limit}&offset=${offset}${filterStr}` :
        `/users?limit=${limit}&offset=${offset}`
      api(accessToken)
        .get(url)
        .then((response) => {
          dispatch({ type: GET_USERS, payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: GET_USERS_ERR, payload: error.message });
          if (error.response !== undefined) {
            toast.error(error.response.data.detail);
          }
        });
    };

export const addUser = (accessToken, data) => (dispatch) => {
  api(accessToken)
    .post("/user", data)
    .then((response) => {
      dispatch({ type: ADD_USER, payload: response.data });
      toast.success("User successfully added.");
    })
    .catch((error) => {
      dispatch({ type: ADD_USER_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};

export const updateUserWithID = (accessToken, newData) => (dispatch) => {
  api(accessToken)
    .put(`/user/${newData.id}`, newData)
    .then((response) => {
      dispatch({ type: UPDATE_USER, payload: response.data });
      toast.success("User successfully updated.");
    })
    .catch((error) => {
      dispatch({ type: UPDATE_USER_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};

export const deleteUserWithID = (accessToken, id) => (dispatch) => {
  api(accessToken)
    .delete(`/user/${id}`)
    .then(() => {
      dispatch({ type: DELETE_USER, payload: id });
      toast.success("User successfully deleted.");
    })
    .catch((error) => {
      dispatch({ type: DELETE_USER_ERR, payload: error.message });
      if (error.response !== undefined) {
        toast.error(error.response.data.detail);
      }
    });
};
