import {
    DELETE_SALES_OWNER,
    UPDATE_SALES_OWNER,
    GET_SALES_OWNERS,
    ADD_SALES_OWNER,
} from '../actions/types';

const SALES_OWNER_INITIAL_STATE = {
    salesOwnerList: [],
    totalSalesOwnerCount: 0,
};

const salesOwnerReducer = (state = SALES_OWNER_INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SALES_OWNERS:
            return {
                ...state,
                salesOwnerList: action.payload.sales_owners,
                totalSalesOwnerCount: action.payload.totalSalesOwnerCount,
            };
        case ADD_SALES_OWNER:
            return { ...state, sales_owner: action.payload };
        case DELETE_SALES_OWNER:
            return {
                ...state,
                salesOwnerList: state.salesOwnerList.filter((sales_owner) => sales_owner !== action.payload),
            };
        case UPDATE_SALES_OWNER:
            return {
                ...state,
                salesOwnerList: state.salesOwnerList.filter(
                    (sales_owner) => sales_owner.id === action.payload.id
                ),
            };
        default:
            return state;
    }
};

export default salesOwnerReducer;
